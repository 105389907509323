// ** React
import React, { useEffect, useState } from 'react';

// ** MUI Import
import { makeStyles } from '@material-ui/core/styles';

// ** Gatsby
import { graphql } from 'gatsby';

// ** Layout
import Layout from '../containers/layout';

// ** Custom Components
import Container from '../components/layout/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/layout/seo';
import {
  RenderDaysOffered,
  RenderStartTimes,
  campusOptions,
  collegeOptions,
  createURLQueryFromArray,
  days,
  degreeOptions,
  getObjDiff,
  getQueryParametersFromURL,
  times,
} from './search';

// ** Library
import { useForm, useWatch } from 'react-hook-form';

// ** Utils
import { cn, paginate } from '../lib/helpers';

// ** Styles
import { responsiveTitle2 } from '../styles/typography.module.css';
import { Box, Button, Grid } from '@material-ui/core';
import {
  FormInputDropdown,
  FormInputMultiCheckbox,
  FormInputRadio,
} from '../components/forms/searchForm/formComponents';
import GridCard from '../components/grids/grid-card';
import FilterForm from '../components/forms/filter-form';
import { Tune } from '@material-ui/icons';
import { courseMap } from '../../gatsbyHelpers';
import useDeepCompareEffect from '../lib/deepEqual';

import { termDefault } from '../utils/terms_to_include';
import { assignTimeDesignations } from '../../gatsbyHelpers';

const useStyles = makeStyles((theme) => ({
  courseGrid: {
    '& a, & a p': {
      color: '#252525',
    },
    '& img': {
      objectFit: 'fill !important',
    },
    '& a > div > div:first-child': {
      aspectRatio: 1 / 1,
    },
    '& .MuiPagination-ul': {
      flexWrap: 'nowrap',
    },
  },
  titleStyle: {
    margin: '4rem 0 4rem 0 !important',
    textAlign: 'left !important',

    [theme.breakpoints.down('sm')]: {
      margin: '2rem 0 2rem 0 !important',
    },
  },
  dropdownWrapper: {
    marginBottom: '1rem',
  },
  selectWrapper: {
    marginBottom: '1rem',
  },
  mobileFilter: {
    display: 'block',

    [theme.breakpoints.up(900)]: {
      display: 'none',
    },
  },
  desktopFilter: {
    display: 'none',

    [theme.breakpoints.up(900)]: {
      display: 'block',
    },
  },
  filterButton: {
    fontFamily: 'Raleway, -apple-system, BlinkMacSystemFont, sans-serif',
    textTransform: 'capitalize',
    color: 'var(--color-black)',
    maxWidth: '200px',
    fontSize: '1em',
    marginBottom: '1rem',
    border: '1px solid #000',
    width: '100%',
    height: '3.125rem',
    padding: '1rem .5rem',
  },
}));

export const query = graphql`
  query DepartmentTemplateQuery($id: String!, $termsToIncludeArray: [String]) {
    site {
      meta: siteMetadata {
        siteUrl
      }
    }
    page: sanityDepartment(id: { eq: $id }) {
      title
      slug {
        current
      }
      id
    }
    allCourse: allSanityCourse(
      sort: { order: ASC, fields: courseNumber }
      filter: {
        department: { id: { eq: $id } }
        sections: { elemMatch: { term: { in: $termsToIncludeArray } } }
      }
    ) {
      nodes {
        ...Course
      }
    }
    siteSetting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      fallbackImage {
        _key
        alt
        image {
          asset {
            _id
            url
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const CourseTemplate = (props) => {
  const isDebugDepartment = false;
  const { location, pageContext } = props;
  const { courseData } = pageContext;
  const { terms } = courseData;
  const termOptions = terms.map((term) => ({ label: term, value: term }));
  const classes = useStyles();
  const {
    data: { page, allCourse, siteSetting },
    errors,
  } = props;
  const mappedAllCourses = allCourse.nodes.map((course) => courseMap(course));

  const defaultValues = {
    campus: campusOptions.map((campus) => campus.value),
    collegeType: 'college',
    daysOffered: days.map((day) => day.value),
    degree: degreeOptions.map((degree) => degree.value),
    searchValue: '',
    term: termDefault,
    timeOffered: times.map((time) => time.value),
    preReqs: [],
  };

  const [showPagination, setShowPagination] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const { title } = page;
  const { fallbackImage } = siteSetting;
  const { search } = location;
  const urlQuery = getQueryParametersFromURL(search, defaultValues);
  const methods = useForm({ defaultValues: urlQuery });
  const { control, watch, handleSubmit } = methods;
  const [query, setQuery] = useState('');
  const data = useWatch({ control });
  const [dataToShow, setDataToShow] = useState(mappedAllCourses);

  useDeepCompareEffect(() => {
    const formDiff = getObjDiff({ term: '' }, data);
    const urlQuery = createURLQueryFromArray(formDiff, '?');
    setQuery(urlQuery);
  }, [data, urlQuery, defaultValues]);

  isDebugDepartment && console.log(`*** SVU: department.js: watch:`, watch());

  useEffect(() => {
    const regex = /^AE/i;
    if (watch('collegeType') === 'preCollege') {
      setDataToShow(mappedAllCourses.filter((course) => regex.test(course.courseNumber)));
    } else {
      setDataToShow(
        mappedAllCourses.filter((course) => {
          if (watch('degree').length > 0) {
            return watch('degree').some((degree) => course.degree === degree);
          } else if (watch('degree').length === degreeOptions.length) {
            return course.degree === 'GRAD' || course.degree === 'UGRD';
          } else if (watch('degree').length === 0) {
            return course.degree === 'NM';
          }
        })
      );
    }
  }, [watch('collegeType'), watch('degree'), setDataToShow]);


  isDebugDepartment && console.log(`*** SVU: department.js: dataToShow`, dataToShow);
  
  /*
   * Filter Courses at course Section level
   *
   * Some filters require filtering by course section instead of by course:
   *  1. Campus (modality)
   *  2. Term
   *  3. Days offered
   *  4. Times offered  
   */

  // get all sections for the School
  let courseSections = [];
  dataToShow.forEach( (course) => {
    (course.sections).forEach( (section) => {
      const sectionTimeDesignations = assignTimeDesignations([section?.hours]); // assignTimeDesignations() expects an array
      // add the following properties to each section object so they can used in later filtering: 
      //    - parent course number for the section
      //    - the calculated time designation(s) for the section's class time (ie, 'hours')
      courseSections.push({
        ...section, 
        courseNum: course.courseNumber,
        sectionTimeDesignations: sectionTimeDesignations
      });
    });
  });

  isDebugDepartment && console.log(`*** SVU: department.js: courseSections`, courseSections);

  // --- filter sections ---

  // filter sections by Campus (aka, modality)
  let filteredCourseSections = courseSections.filter( (section) => {
    if (watch('campus')?.some((campus) => section.modality === campus)) {
      return section;
    } else if (watch('campus')?.length === campusOptions.length) { // no filter condition
      return section;
    }
  });
  isDebugDepartment && console.log(`*** SVU: department.js: filteredCourseSections after CAMPUS (modality) filter`, filteredCourseSections);

  // filter sections by Term
  filteredCourseSections = filteredCourseSections.filter( (section) => {
    if (Array.isArray(watch('term'))) {
      return section.term === watch('term')[0]; // no filter condition
    } else {
      return section.term === watch('term');
    }
  });
  isDebugDepartment && console.log(`*** SVU: department.js: filteredCourseSections after TERM filter`, filteredCourseSections);

  // filter sections by Days Offered
  filteredCourseSections = filteredCourseSections.filter( (section) => {
    if (watch('daysOffered')?.length === days.length) { // no filter condition
      return section;
    } else {
      // if any of the section's days is included the day filters, then include the section
      //    - examples of watch('daysOffered'): ['M', 'T', 'W', 'R', 'F', 'SA', 'MR'], ['M', 'T']
      //    - examples of section.day: 'W', 'MTWR', ''
      const sectionDays = section.day.split(''); // e.g., 'MW' => ['M', 'W']      
      if (sectionDays?.some( (sectionDay) => watch('daysOffered')?.includes(sectionDay))) {
        return section;
      }
    }    
  });
  isDebugDepartment && console.log(`*** SVU: department.js: filteredCourseSections after DAYSOFFERED filter`, filteredCourseSections);

  // filter sections by Times Offerred
  filteredCourseSections = filteredCourseSections.filter( (section) => {
    if (watch('timeOffered')?.length === times.length) { // no filter condition
      return section;
    }
    else {
      // if any of the section's time designations is included the time filters, then include the section
      //    - examples of watch('timeOffered'): ['morning', 'noon', 'afternoon', 'evening'], ['noon', 'afternoon']
      //    - examples of sectionTimeDesignations: ['morning', 'noon'], ['afternoon'], []
      const sectionTimeDesignations = section.sectionTimeDesignations;
      if (sectionTimeDesignations.some( (sectionTimeDesignation) => watch('timeOffered').includes(sectionTimeDesignation) )) {
        return section;
      }
    } 
  });
  isDebugDepartment && console.log(`*** SVU: department.js: filteredCourseSections after TIMEOFFERED filter`, filteredCourseSections);

  // get the unique courses from the filtered sections (preserve course order so don't use Set constructor)
  let filteredCourseNums = [];
  filteredCourseSections.forEach( (section) => {
    const courseNum = section.courseNum;
    if (!filteredCourseNums.includes(courseNum)) {
      filteredCourseNums.push(courseNum);
    }
  });
  isDebugDepartment && console.log(`*** SVU: department.js: filteredCourseNums:`, filteredCourseNums);

  let filteredCoursesUsingSections = dataToShow.filter( (course) => {
    return filteredCourseNums.includes(course.courseNumber);
  });
  isDebugDepartment && console.log(`*** SVU: department.js: filteredCoursesUsingSections:`, filteredCoursesUsingSections);

  // let filteredCourses = filteredCourses.filter((course) => {
  //     if (watch('campus')?.some((campus) => course.campuses.includes(campus))) {
  //       return course;
  //     } else if (watch('campus')?.length === campusOptions.length) {
  //       return course;
  //     }
  //   });
  //   isDebugDepartment && console.log(`*** SVU: department.js: filteredCourses after CAMPUS (modality) filter`, filteredCourses);

  //   filteredCourses = filteredCourses.filter((course) => {
  //     if (Array.isArray(watch('term'))) {
  //       return course.terms.some((term) => term === watch('term')[0]);
  //     } else {
  //       return course.terms.some((term) => term === watch('term'));
  //     }
  //   });
  //   isDebugDepartment && console.log(`*** SVU: department.js: filteredCourses after TERM filter`, filteredCourses);

  
  // filteredCourses = filteredCourses.filter((course) => {
  //   if (watch('daysOffered')?.some((day) => course.days.includes(day))) {
  //     return course;
  //   } else if (watch('daysOffered')?.length === days.length) {
  //     return course;
  //   }
  // });
  // isDebugDepartment && console.log(`*** SVU: department.js: filteredCourses after DAYOFFERED filter`, filteredCourses);

  
  // let filteredCourses = filteredCourses.filter((course) => {
  //   if (watch('timeOffered')?.some((time) => course.hours.includes(time))) {
  //     return course;
  //   } else if (watch('timeOffered')?.length === times.length) { // no filter condition
  //     return course;
  //   }
  // });
  // isDebugDepartment && console.log(`*** SVU: department.js: filteredCourses after TIMEOFFERED filter`, filteredCourses);

  /*
   * Filter Courses at course level
   */

  // filter courses by pre-reqs (ie, PEUG)
  let filteredCourses = filteredCoursesUsingSections.filter((course) => {
    if (watch('preReqs').length > 0 && course.courseAttributes === 'PEUG') {
      return course;
    } else if (watch('preReqs').length === 0) { // no filter condition
      return course;
    }
  });
  isDebugDepartment && console.log(`*** SVU: department.js: filteredCourses after PREREQS filter`, filteredCourses);

  useEffect(() => {
    handlePagination(1, filteredCourses);
  }, [filteredCourses.length]);

  let paginationData = paginate(1, filteredCourses.length);
  const [pages, setPages] = useState(paginationData.pages.length);
  const [paginationLocation, setPaginationLocation] = useState([0, paginationData.endIndex]);

  function handlePagination(num, data = filteredCourses) {
    paginationData = paginate(num, data.length);
    setPages(paginationData.pages.length);
    setPaginationLocation([paginationData.startIndex, paginationData.endIndex]);
  }

  const paginatedData = filteredCourses.slice(paginationLocation[0], paginationLocation[1]);

  const handleOnShowAll = () => {
    setShowPagination(false);
  };

  const handleOnMobileFilterClick = () => {
    setShowFilterModal(true);
  };

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {title && <SEO title={title} />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <Container>
        {title ? <h2 className={cn(responsiveTitle2, classes.titleStyle)}>{title}</h2> : ''}

        <Box className={classes.mobileFilter}>
          <Button
            onClick={handleOnMobileFilterClick}
            className={classes.filterButton}
            endIcon={<Tune fontSize="small" />}
          >
            Filter
          </Button>
        </Box>

        <Box className={classes.desktopFilter}>
          <Box className={classes.filterContainer}>
            <Box mb={2}>
              <FormInputRadio name="collegeType" control={control} options={collegeOptions} />
              <FormInputMultiCheckbox
                control={control}
                name="preReqs"
                options={[
                  {
                    value: 'preReqs',
                    label: 'Non-Degree Seeking Classes',
                  },
                ]}
              />
            </Box>
            <Grid classes={classes.dropdownWrapper} container direction="row" spacing={2}>
              <Grid item>
                <FormInputDropdown control={control} label="Campuses" multiple name="campus" options={campusOptions} />
              </Grid>
              <Grid item>
                <FormInputDropdown control={control} label="Terms" name="term" options={termOptions} />
              </Grid>
              {watch('collegeType') === 'college' && (
                <Grid item>
                  <FormInputDropdown control={control} label="Degrees" multiple name="degree" options={degreeOptions} />
                </Grid>
              )}
            </Grid>
            <Grid className={classes.selectWrapper} container direction="row" spacing={2}>
              <Grid item>
                <RenderDaysOffered control={control} />
              </Grid>
              <Grid item>
                <RenderStartTimes control={control} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div className={classes.courseGrid}>
          <GridCard
            query={query}
            data={showPagination ? paginatedData : filteredCourses}
            fallbackImage={fallbackImage}
            showPagination={showPagination}
            pages={pages}
            handlePagination={handlePagination}
            handleOnShowAll={handleOnShowAll}
            loadToScroll
          />
        </div>
      </Container>

      {showFilterModal && (
        <FilterForm
          defaultValues={defaultValues}
          control={control}
          handleSubmit={handleSubmit}
          hideSearchDrawer={() => setShowFilterModal(false)}
        />
      )}
    </Layout>
  );
};

export default CourseTemplate;
